import {IPaymentIntent} from "../model/payment_intent";
import {CompassError, ErrorCode} from "../model/compass_error";
import {Payable} from "../model/payable";

// Monkey patch BigInt to make it JSON serializable
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

type BackendResponse = {
  error?: string;
  userFriendlyMessage?: string;
  payable?: Payable;
  canRetryIn?: Date;
}

type CreatePaymentIntentParams = {
  paymentIntent: IPaymentIntent;
}

type DiscardPaymentIntentParams = {
  paymentIntent: IPaymentIntent
}

interface DiscardPaymentIntentResponse extends BackendResponse {
}

type MarkPaymentIntentAsSentParams = {
  paymentIntent: IPaymentIntent;
}

interface MarkPaymentIntentAsSentResponse extends BackendResponse {
}

type RejectPaymentIntentParams = {
  paymentIntent: IPaymentIntent
}

interface RejectPaymentIntentResponse extends BackendResponse {
}

interface ConfirmPaymentIntentResponse extends BackendResponse {
  guid: string;
}

type ConfirmPaymentIntentParams = {
  paymentIntent: IPaymentIntent;
  transactionHash: string;
}

interface ConfirmPaymentIntentResponse extends BackendResponse {
}

type ReplaceTransactionParams = {
  paymentIntent: IPaymentIntent;
  newTransactionHash: string;
}

interface ReplaceTransactionResponse extends BackendResponse {
}

type ProcessPaymentIntentParams = {
  paymentIntent: IPaymentIntent
}

interface ProcessPaymentIntentResponse extends BackendResponse {
}

type FailPaymentIntentParams = {
  paymentIntent: IPaymentIntent,
  failReason?: string
}

interface FailPaymentIntentResponse extends BackendResponse {
}

type BackendApiClientParams = {
  compassBaseUrl: string;
}

export interface IBackendApiClient {

  createPaymentIntent({
                        paymentIntent
                      }: CreatePaymentIntentParams): Promise<string>;

  discardPaymentIntent({
                         paymentIntent
                       }: DiscardPaymentIntentParams): Promise<void>;

  markPaymentIntentAsSent({
                        paymentIntent
                      }: MarkPaymentIntentAsSentParams): Promise<void>;

  rejectPaymentIntent({
                         paymentIntent
                       }: RejectPaymentIntentParams): Promise<void>

  confirmPaymentIntent({
                         paymentIntent,
                         transactionHash
                       }: ConfirmPaymentIntentParams): Promise<void>

  replaceTransaction({
                         paymentIntent,
                         newTransactionHash
                       }: ReplaceTransactionParams): Promise<void>

  processPaymentIntent({
                        paymentIntent
                      }: ProcessPaymentIntentParams): Promise<void>

  failPaymentIntent({
                      paymentIntent,
                      failReason
                    }: FailPaymentIntentParams): Promise<void>
}

export class BackendApiClient implements IBackendApiClient {
  createPaymentIntentUrl: string;
  markPaymentIntentAsSentUrl: string;
  rejectPaymentIntentUrl: string;
  confirmPaymentIntentUrl: string;
  replaceTransactionUrl: string;
  processPaymentIntentUrl: string;
  failPaymentIntentUrl: string;

  constructor({compassBaseUrl}: BackendApiClientParams) {
    this.createPaymentIntentUrl = `${compassBaseUrl}`;
    this.markPaymentIntentAsSentUrl = `${compassBaseUrl}`;
    this.rejectPaymentIntentUrl = `${compassBaseUrl}`;
    this.confirmPaymentIntentUrl = `${compassBaseUrl}`;
    this.replaceTransactionUrl = `${compassBaseUrl}`;
    this.processPaymentIntentUrl = `${compassBaseUrl}`;
    this.failPaymentIntentUrl = `${compassBaseUrl}`;
  }

  async createPaymentIntent({
                              paymentIntent
                            }: CreatePaymentIntentParams): Promise<string> {
    const paymentIntentJSON = paymentIntent.toBackendJson();
    const confirmPaymentIntentResponse = await this.post<ConfirmPaymentIntentResponse>(this.createPaymentIntentUrl, [
      ['payable_guid', paymentIntent.payable.guid!],
      ['payable_model_name', paymentIntent.payable.modelName],
      ['tx_intent[from_address_identifier]', paymentIntent.paymentMethod.addressIdentifier],
      ['tx_intent[to_address_identifier]', paymentIntentJSON.to_address_identifier],
      ['tx_intent[via_address_identifier]', paymentIntentJSON.via_address_identifier],
      ['tx_intent[token_id]', paymentIntentJSON.token_id],
      ['tx_intent[amount]', paymentIntentJSON.amount],
      ['tx_intent[gas]', paymentIntentJSON.gas],
      ['tx_intent[gas_price]', paymentIntentJSON.gas_price],
      ['tx_intent[data]', paymentIntentJSON.data],
      ['tx_intent[value]', paymentIntentJSON.value],
      ['tx_intent[raw_transaction]', JSON.stringify(paymentIntent.preparedTransaction?.transaction)],
    ]);
    this.handleBackendError(confirmPaymentIntentResponse);
    return confirmPaymentIntentResponse.guid;
  }

  async discardPaymentIntent({
                            paymentIntent
                          }: DiscardPaymentIntentParams): Promise<void> {
    const discardPaymentIntentResponse = await this.patch<DiscardPaymentIntentResponse>(`${this.failPaymentIntentUrl}/${paymentIntent.guid}`, [
      ['step', 'discard']
    ]);
    this.handleBackendError(discardPaymentIntentResponse);
  }

  public async markPaymentIntentAsSent({
                                      paymentIntent
                                    }: MarkPaymentIntentAsSentParams): Promise<void> {
    const markPaymentIntentAsSentResponse = await this.patch<MarkPaymentIntentAsSentResponse>(`${this.markPaymentIntentAsSentUrl}/${paymentIntent.guid}`, [
      ['step', 'mark_as_sent']
    ]);
    this.handleBackendError(markPaymentIntentAsSentResponse);
  }

  public async rejectPaymentIntent({
                                      paymentIntent
                                    }: RejectPaymentIntentParams): Promise<void> {

    const rejectPaymentIntentResponse = await this.patch<RejectPaymentIntentResponse>(`${this.rejectPaymentIntentUrl}/${paymentIntent.guid}`, [
      ['token_id', paymentIntent.paymentMethod.token.id],
      ['address_identifier', paymentIntent.paymentMethod.addressIdentifier],
      ['step', 'reject']
    ]);
    this.handleBackendError(rejectPaymentIntentResponse);
  }

  public async confirmPaymentIntent({
                                      paymentIntent,
                                      transactionHash
                                    }: ConfirmPaymentIntentParams): Promise<void> {

    const confirmPaymentIntentResponse = await this.patch<ConfirmPaymentIntentResponse>(`${this.confirmPaymentIntentUrl}/${paymentIntent.guid}`, [
      ['token_id', paymentIntent.paymentMethod.token.id],
      ['tx_identifier', transactionHash],
      ['address_identifier', paymentIntent.paymentMethod.addressIdentifier],
      ['step', 'confirm']
    ]);
    this.handleBackendError(confirmPaymentIntentResponse);
  }

  public async replaceTransaction({
                                      paymentIntent,
                                      newTransactionHash
                                    }: ReplaceTransactionParams): Promise<void> {

    const replaceTransactionResponse = await this.patch<ReplaceTransactionResponse>(`${this.replaceTransactionUrl}/${paymentIntent.guid}`, [
      ['token_id', paymentIntent.paymentMethod.token.id],
      ['tx_identifier', newTransactionHash],
      ['address_identifier', paymentIntent.paymentMethod.addressIdentifier],
      ['step', 'replace_transaction']
    ]);
    this.handleBackendError(replaceTransactionResponse);
  }

  async processPaymentIntent({
                              paymentIntent
                            }: ProcessPaymentIntentParams): Promise<void> {
    const processPaymentIntentResponse = await this.patch<ProcessPaymentIntentResponse>(`${this.processPaymentIntentUrl}/${paymentIntent.guid}`, [
      ['step', 'process']
    ]);
    this.handleBackendError(processPaymentIntentResponse);
  }

  async failPaymentIntent({
                            paymentIntent,
                            failReason
                          }: FailPaymentIntentParams): Promise<void> {
    const failPaymentIntentResponse = await this.patch<FailPaymentIntentResponse>(`${this.failPaymentIntentUrl}/${paymentIntent.guid}`, [
      ['step', 'fail'],
      ['fail_reason', failReason || '']
    ]);
    this.handleBackendError(failPaymentIntentResponse);
  }

  private handleBackendError(backendResponse: BackendResponse): void {
    if (backendResponse.error) throw new CompassError({
      code: ErrorCode.BackendApiError,
      message: backendResponse.error,
      userFriendlyMessage: backendResponse.userFriendlyMessage,
      payable: backendResponse.payable,
      canRetryIn: backendResponse.canRetryIn,
    });
  }

  private async post<T extends BackendResponse>(url: string, data: Array<[string, string]>): Promise<T> {
    return this.request(url, 'POST', data);
  }

  private async patch<T extends BackendResponse>(url: string, data: Array<[string, string]>): Promise<T> {
    return this.request(url, 'PATCH', data);
  }

  private async request<T extends BackendResponse>(url: string, method: string = 'POST', data: Array<[string, string]>): Promise<T> {
    const requestBody = new URLSearchParams();
    for (const pair of data) {
      requestBody.append(pair[0], pair[1]);
    }
    let response = await fetch(url, {
      method: method,
      headers: {
        'X-CSRF-Token': this.getCSRFToken()
      },
      body: requestBody
    });
    if (response.ok) {
      let body = await response.json();
      return Promise.resolve(body as T);
    } else {
      let userFriendlyMessage, payable, canRetryIn;
      if (response.status === 422) {
        let body = await response.json();
        userFriendlyMessage = body.userFriendlyMessage;
        payable = body.payable;
        canRetryIn = body.canRetryIn;
      } else {
        userFriendlyMessage = 'Something went wrong. Please try again or contact an admin if the problem persists.';
      }

      throw new CompassError({
        code: ErrorCode.BackendApiError,
        message: response.statusText,
        userFriendlyMessage: userFriendlyMessage,
        payable: payable,
        canRetryIn: canRetryIn
      });
    }
  }

  private getCSRFToken(): string {
    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;
    if (!csrfTokenElement) {
      throw new CompassError({
        code: ErrorCode.NoCSRFTokenError,
        message: 'CSRF token not found',
        userFriendlyMessage: 'Please refresh the page and try again.'
      });
    }
    return csrfTokenElement.content;
  }
}
