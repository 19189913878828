import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    addEventListener('turbo:before-stream-render', (e) => {
      this.beforeStreamRender(e);
    });
    this.findAndSetBest();
  }

  findUnitPricesAndRows() {
    var unitPriceElements = this.element.querySelectorAll('[data-unit-price]');
    // Iterate through each element
    let unitPricesAndRows = [];
    unitPriceElements.forEach((element) => {
      // Get the data-unit-price value
      var unitPrice = parseFloat(element.getAttribute('data-unit-price'));

      // Find the closest row (tr) for the current element within the table
      var row = element.closest('tr');

      unitPricesAndRows.push([unitPrice, row]);
    });

    return unitPricesAndRows;
  }

  beforeStreamRender(event) {
    let pattern = /otc_order_table_rows_market_buy_deal_platform_\d+_offer_\d+/;
    if (pattern.test(event.target.outerHTML)) {
      const defaultAction = event.detail.render;
      event.detail.render = (streamElement) => {
        try {
          this.processStream(streamElement, defaultAction);
        } catch (error) {
          console.error(error);
        }
      };
    }
  }

  processStream(streamElement, defaultAction) {
    if (['prepend'].includes(streamElement.action)) {
      var newRow = streamElement.children[0].content;
      const newRowUnitPrice = parseFloat(
        newRow
          .querySelector('[data-unit-price]')
          .getAttribute('data-unit-price')
      );
      const unitPricesAndRows = this.findUnitPricesAndRows();

      // When there are no existing rows just continue the normal stream
      if (unitPricesAndRows.length === 0) {
        defaultAction(streamElement);
      } else {
        // Extract the unit prices from the list
        const unitPrices = unitPricesAndRows.map((item) => item[0]);

        // Find the largest unit price
        const lowestUnitPrice = Math.min(...unitPrices);

        if (newRowUnitPrice >= lowestUnitPrice) {
          let rowToInsertBefore = null;
          for (const [unitPrice, row] of unitPricesAndRows) {
            if (newRowUnitPrice >= unitPrice) {
              break;
            } else {
              rowToInsertBefore = row;
            }
          }

          if (!rowToInsertBefore) {
            // Insert at the top of the list
            defaultAction(streamElement);
          } else {
            rowToInsertBefore.parentNode.insertBefore(
              newRow,
              rowToInsertBefore.nextSibling
            );
          }

          this.findAndSetBest();
        } else {
          const referenceNode =
            unitPricesAndRows[unitPricesAndRows.length - 1][1];

          referenceNode.parentNode.insertBefore(
            newRow,
            referenceNode.nextSibling
          );
        }
      }
    }
  }

  findAndSetBest() {
    const unitPricesAndRows = this.findUnitPricesAndRows();

    // Extract the unit prices from the list
    const unitPrices = unitPricesAndRows.map((item) => item[0]);

    // Find the largest unit price
    const largestUnitPrice = Math.max(...unitPrices);

    // Find the row with the largest unit price
    const match = unitPricesAndRows.find(
      (item) => item[0] === largestUnitPrice
    );
    const rowWithLargestUnitPrice = match && match[1];

    if (rowWithLargestUnitPrice) {
      // Find elements that contains span element with class "best-star" among the unitPricesAndRows list
      const bestStarElements = unitPricesAndRows.map((item) =>
        item[1].querySelector('.best-star')
      );

      // Add class "!hidden" from best-star element if it's not in the row with the largest unit price
      bestStarElements.forEach((element) => {
        if (element.closest('tr') !== rowWithLargestUnitPrice) {
          element.classList.add('!hidden');
        }
      });

      // Find elements that contains div element with class "best-space" among the unitPricesAndRows list
      const bestSpaceElements = unitPricesAndRows.map((item) =>
        item[1].querySelector('.best-space')
      );

      // Add class "!hidden" from best-space element if it's not in the row with the largest unit price
      bestSpaceElements.forEach((element) => {
        if (element.closest('tr') !== rowWithLargestUnitPrice) {
          element.classList.add('!hidden');
        }
      });

      // For the row with the largest unit price, remove class "!hidden" from best-star element and add class "!hidden" to best-space element
      rowWithLargestUnitPrice
        .querySelector('.best-star')
        .classList.remove('!hidden');
      rowWithLargestUnitPrice
        .querySelector('.best-space')
        .classList.add('!hidden');
    }
  }
}
