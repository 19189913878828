import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.handleFileAcceptance();
    this.addEmbedButtonToToolbar();
    this.listenForEmbedButtonClick();
  }

  handleFileAcceptance() {
    window.addEventListener('trix-file-accept', (event) => {
      const acceptedTypes = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'image/gif',
      ];
      const maxFileSize = 1024 * 1024; // 1MB

      if (event.file.size > maxFileSize) {
        event.preventDefault();
        alert('Only support attachment files up to size 1MB!');
      }

      if (!acceptedTypes.includes(event.file.type)) {
        event.preventDefault();
        alert('Only support jpeg, png, or gif images');
      }
    });
  }

  addEmbedButtonToToolbar() {
    const toolbarElement = this.element.toolbarElement;
    if (!toolbarElement) return;

    const fileToolsGroup = toolbarElement.querySelector(
      '.trix-button-group--file-tools'
    );
    if (!fileToolsGroup) return;

    const embedButton = document.createElement('button');
    embedButton.type = 'button';
    embedButton.className =
      'trix-button trix-button--icon trix-button--icon-embed';
    embedButton.setAttribute('data-trix-attribute', 'video-embed');
    embedButton.title = 'Embed YT Video';
    embedButton.tabIndex = '-1';

    const attachFilesButton = fileToolsGroup.querySelector(
      '.trix-button--icon-attach'
    );
    attachFilesButton
      ? attachFilesButton.after(embedButton)
      : fileToolsGroup.appendChild(embedButton);
  }

  listenForEmbedButtonClick() {
    const toolbarElement = this.element.toolbarElement;
    toolbarElement.addEventListener('click', (event) => {
      if (
        event.target.type !== 'button' ||
        event.target.getAttribute('data-trix-attribute') !== 'video-embed'
      )
        return;
      const url = prompt('Enter YouTube video URL:');
      if (this.isValidYouTubeURL(url)) {
        this.insertYouTubeThumbnail(this.getYouTubeID(url));
      } else {
        alert('Invalid YouTube URL!');
      }
    });
  }

  insertYouTubeThumbnail(videoId) {
    const content = this.generateThumbnailContent(videoId);
    this.element.editor.insertHTML(content);
  }

  generateThumbnailContent(videoId) {
    return `<img src="https://img.youtube.com/vi/${videoId}/maxresdefault.jpg"/>`;
  }

  isValidYouTubeURL(url) {
    const pattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}(\S*)?$/;
    return pattern.test(url);
  }

  getYouTubeID(url) {
    let video_id = url.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition !== -1)
      video_id = video_id.substring(0, ampersandPosition);
    return video_id;
  }
}
