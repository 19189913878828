import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.setActiveTab();
  }

  setActiveTab() {
    const currentRailsController = document.querySelector(
      '[data-rails-controller-name]'
    ).dataset?.railsControllerName;

    if (!currentRailsController) {
      return;
    }

    const selectedTab = document.querySelector(`[data-nav-tabs-href="${currentRailsController}"]`)
    if (selectedTab) {
      selectedTab.setAttribute('aria-current', 'page');
      selectedTab.classList += " " + selectedTab.dataset.activeOnClasses;
    }
  }
}
