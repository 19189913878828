import { BackendApiClient, IBackendApiClient } from '../api/backend_api_client';
import { IPaymentIntent } from '../model/payment_intent';
import { IWeb3Client } from '../web3/web3_client';
import { PreparedTransaction } from '../model/prepared_transaction';
import { CompassError, ErrorCode } from '../model/compass_error';
import {
  ApproveAllowanceCallbacks,
  ApproveAllowanceParams,
  CompassAPI,
  CreatePaymentIntentParams,
  HasEnoughAllowanceParams,
  PayCallbacks,
  PayParams,
  PrepareApproveAllowanceParams,
} from './compass_api';

type CompassParams = {
  web3Client: IWeb3Client;
  compassBaseUrl: string;
};

export class Compass implements CompassAPI {
  backendApiClient: IBackendApiClient;
  web3Client: IWeb3Client;

  constructor({ web3Client, compassBaseUrl }: CompassParams) {
    this.backendApiClient = new BackendApiClient({ compassBaseUrl });
    this.web3Client = web3Client;
  }

  public async createPaymentIntent({
    paymentIntent,
  }: CreatePaymentIntentParams): Promise<string> {
    return this.backendApiClient.createPaymentIntent({ paymentIntent });
  }

  public getPreparedTransaction(
    paymentIntent: IPaymentIntent
  ): Promise<PreparedTransaction> {
    return this.web3Client.getPreparedTransaction(paymentIntent);
  }

  public async discardPaymentIntent(
    paymentIntent: IPaymentIntent
  ): Promise<void> {
    return this.backendApiClient.discardPaymentIntent({ paymentIntent });
  }

  public async pay(
    { paymentIntent }: PayParams,
    {
      onWalletConfirmationRequested,
      onTransactionSubmitted,
      onPaymentIntentConfirmed,
      onPaymentIntentConfirmationError,
      onTransactionReplaced,
      onTransactionCancelled,
    }: PayCallbacks
  ): Promise<void> {
    try {
      if (onWalletConfirmationRequested)
        onWalletConfirmationRequested(paymentIntent);

      // Intentionally not awaited
      this.backendApiClient.markPaymentIntentAsSent({ paymentIntent });

      let confirmPaymentResultError: Error | undefined;
      let transactionReplaced: boolean = false;
      let transactionCancelled: boolean = false;
      await this.web3Client.pay(
        paymentIntent,
        async (transactionHash: string) => {
          // The user accepted the transaction and it has been submitted to the blockchain
          if (onTransactionSubmitted) onTransactionSubmitted(transactionHash);

          try {
            // As soon as we have the transaction hash, send it to the backend
            await this.backendApiClient.confirmPaymentIntent({
              paymentIntent: paymentIntent,
              transactionHash: transactionHash,
            });
            // The backend confirmed the payment intent (assigned the hash to the intent)
            if (onPaymentIntentConfirmed)
              onPaymentIntentConfirmed(paymentIntent);
          } catch (error) {
            confirmPaymentResultError = error as Error;
          }
        },
        async (newTransactionHash: string) => {
          transactionReplaced = true;
          if (onTransactionReplaced)
            onTransactionReplaced(paymentIntent, newTransactionHash);
          await this.backendApiClient.replaceTransaction({
            paymentIntent: paymentIntent,
            newTransactionHash: newTransactionHash,
          });
          // At this point the new TX is already confirmed in the blockchain.
          await this.backendApiClient.processPaymentIntent({
            paymentIntent: paymentIntent,
          });
        },
        async (hash: string) => {
          transactionCancelled = true;
          if (onTransactionCancelled) onTransactionCancelled(paymentIntent);
          await this.backendApiClient.failPaymentIntent({
            paymentIntent: paymentIntent,
            failReason: 'Transaction cancelled',
          });
        }
      );

      if (
        !transactionReplaced &&
        !transactionCancelled &&
        confirmPaymentResultError === undefined
      ) {
        // The web3client received direct confirmation from the blockchain. Request the backend to process the payment intent
        await this.backendApiClient.processPaymentIntent({
          paymentIntent: paymentIntent,
        });
      }
    } catch (error) {
      if (error instanceof CompassError) {
        if (
          await this.isSmartContractNotWhitelistedError(paymentIntent, error)
        ) {
          throw new CompassError({
            code: ErrorCode.SmartContractNotWhitelistedError,
          });
        } else if (error.code == ErrorCode.UserRejectedTransactionError) {
          await this.backendApiClient.rejectPaymentIntent({ paymentIntent });
        }
      } else if (this.isTransactionOrBlockNotFoundByViemError(error as Error)) {
        // Viem failed to find the TX, but it's most likely there. Ask the backend to process the payment intent
        await this.backendApiClient.processPaymentIntent({
          paymentIntent: paymentIntent,
        });
        return;
      } else {
        // Intentionally not awaited
        this.backendApiClient.failPaymentIntent({
          paymentIntent: paymentIntent,
          failReason: (error as Error).message,
        });
        return;
      }
      throw error;
    }
  }

  public async getAddressIdentifier(): Promise<string | undefined> {
    return this.web3Client.getAddress()?.toLowerCase();
  }

  public async getBalance(
    addressIdentifier: string,
    tokenAddressIdentifier: string,
    chainIdentifier: number
  ): Promise<number> {
    return this.web3Client.getBalance(
      addressIdentifier,
      tokenAddressIdentifier,
      chainIdentifier
    );
  }

  public async supportsChain(chainId: number): Promise<boolean> {
    return this.web3Client.supportsChain(chainId);
  }

  public async switchChain(chainId: number): Promise<void> {
    return await this.web3Client.switchChain(chainId);
  }

  public async fetchAllowance({
    paymentIntent,
  }: HasEnoughAllowanceParams): Promise<bigint> {
    return this.web3Client.fetchAllowance(paymentIntent);
  }

  public async getPreparedAllowanceApproval({
    paymentIntent,
  }: PrepareApproveAllowanceParams): Promise<PreparedTransaction> {
    return this.web3Client.getPreparedAllowanceApproval(paymentIntent);
  }

  public async approveAllowance(
    { preparedTransaction }: ApproveAllowanceParams,
    { onTransactionSubmitted }: ApproveAllowanceCallbacks
  ): Promise<void> {
    try {
      return this.web3Client.approveAllowance(
        preparedTransaction,
        onTransactionSubmitted
      );
    } catch (error) {
      if (this.isTransactionOrBlockNotFoundByViemError(error as Error)) {
        // Viem failed to find the TX, but it's most likely there. Don't raise anything, as clients will check
        // the allowance again
        return;
      }
      throw error;
    }
  }

  public getWeb3ConfigInfo() {
    return this.web3Client.getWeb3ConfigInfo();
  }

  private async isSmartContractNotWhitelistedError(
    paymentIntent: IPaymentIntent,
    error: CompassError
  ): Promise<boolean> {
    if (
      paymentIntent.payable.acceptedPaymentMethod !== 'droplet' ||
      error.code !== ErrorCode.InsufficientBalanceError
    )
      return false;

    const balance = await this.getBalance(
      paymentIntent.paymentMethod.addressIdentifier,
      paymentIntent.paymentMethod.token.addressIdentifier,
      paymentIntent.paymentMethod.token.chain.id
    );
    if (balance === undefined) return false;

    // TODO check better
    return paymentIntent.amount! < balance;
  }

  private isTransactionOrBlockNotFoundByViemError(error: Error): boolean {
    return (
      (error.message.includes('Transaction with hash') ||
        error.message.includes('Transaction receipt with hash') ||
        error.message.includes('Block at number')) &&
      error.message.includes('could not be found')
    );
  }
}
