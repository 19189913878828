'use strict';

import Web3Controller from './web3_controller';
import { MagnetUserRejectedRequestError } from '../web3/magnet/errors';
import { flash } from '../lib/flashes';
import Bugsnag from '@bugsnag/js';
import { Web3Account } from '../web3/magnet/types';

export default class extends Web3Controller {
  static targets = [
    'form',
    'switchWalletButton',
    'publicKeyInput',
    'signatureInput',
    'chainIdInput',
    'errorView',
  ];
  declare formTarget: HTMLFormElement;
  declare switchWalletButtonTarget: HTMLButtonElement;
  declare publicKeyInputTarget: HTMLInputElement;
  declare signatureInputTarget: HTMLInputElement;
  declare chainIdInputTarget: HTMLInputElement;

  static values = {
    requestSignableMessageUrl: String,
  };
  declare requestSignableMessageUrlValue: string;

  isRetrievingSignableMessage: boolean = false;

  onAccountDisconnected(account: Web3Account) {
    const isSessionsModal = document.getElementById('sessions-modal-body');
    if (isSessionsModal) {
      this.actionsController?.closeModal();
    }
  }

  async onSwitchWalletButtonClick(event: Event) {
    if (event !== undefined) {
      event.preventDefault();
    }
    this.#setLoadingSwitchWalletButton('Sign the message in your wallet...');
    await this.signMessage(event);
  }

  async signMessage(event: Event) {
    const signableMessage = await this.#requestSignableMessage();

    if (signableMessage === undefined) {
      this.#enableSwitchWalletButton();
      return false;
    }

    // Ask the user to sign the message in their wallet
    try {
      const signedMessage = await this.magnet!.signMessage({
        message: signableMessage,
      });
      if (signedMessage !== undefined) {
        this.#setLoadingSwitchWalletButton('Switching account...');
        this.#filloutAndSubmitForm(
          this.magnet!.web3Account!.address!,
          signedMessage,
          this.magnet!.web3Account!.chain!.id.toString()
        );
      }
    } catch (error: any) {
      this.#enableSwitchWalletButton();
      if (error instanceof MagnetUserRejectedRequestError) {
        // Empty on purpose
      } else if (error.code == 4001) {
        await flash('Wallet closed');
      } else {
        await flash('Invalid signature');
        Bugsnag.notify(`Undefined signed message: ${error.message}`);
        throw error;
      }
    }
  }

  async #requestSignableMessage(): Promise<string | undefined> {
    if (this.isRetrievingSignableMessage) {
      return;
    }

    try {
      this.isRetrievingSignableMessage = true;
      const publicKey = this.magnet?.web3Account?.address;
      if (!publicKey) {
        Bugsnag.notify(
          'Attempt to request signable message before address was set'
        );
        return;
      }

      // Retrieve a signable message from our server
      let response = await this.#fetchSignableMessage(
        this.requestSignableMessageUrlValue,
        publicKey,
        'switch_wallet'
      );

      if (response.success) {
        return response.message;
      } else {
        await flash(response.message);
        Bugsnag.notify(response.message);
        return;
      }
    } catch (error: any) {
      await flash('Signature failed. Refresh page and try again');
      Bugsnag.notify('Signature failed, signable message not ready');
    } finally {
      this.isRetrievingSignableMessage = false;
    }
  }

  async #fetchSignableMessage(
    url: string,
    publicKey: string,
    messageTemplate: string
  ) {
    let response = await fetch(
      `${url}${publicKey}.json?template=${messageTemplate}`
    );

    let body = await response.json();
    return {
      success: response.ok,
      message: body.message,
    };
  }

  #filloutAndSubmitForm(
    publicKey: string,
    signedMessage: string,
    chainId: string
  ) {
    this.signatureInputTarget.value = signedMessage;
    this.publicKeyInputTarget.value = publicKey;
    this.chainIdInputTarget.value = chainId;
    this.formTarget.submit();
  }

  #setLoadingSwitchWalletButton(text = '') {
    this.switchWalletButtonTarget.classList.add('pointer-events-none');
    this.switchWalletButtonTarget.innerHTML =
      '<span class="fa fa-spinner fa-spin"></span>';
  }

  #enableSwitchWalletButton() {
    this.switchWalletButtonTarget.innerHTML =
      this.switchWalletButtonTarget.dataset.defaultText || '';
    this.switchWalletButtonTarget.classList.remove('pointer-events-none');
  }

  get actionsController(): any {
    const actionsControllerElement = document.querySelector(
      '[data-controller~=actions]'
    );
    if (actionsControllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        actionsControllerElement,
        'actions'
      );
    }
  }
}
