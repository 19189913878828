import Web3Controller from '../../javascript/controllers/web3_controller';
import { ICompassConfirmViewInput } from '../../javascript/controllers/compass/payments/confirm/view_input';
import { ICompassConfirmViewOutput } from '../../javascript/controllers/compass/payments/confirm/view_output';
import { ICompassStatusViewOutput } from '../../javascript/controllers/compass/payments/status/view_output';
import { CompassConfirmPresenter } from '../../javascript/controllers/compass/payments/confirm/presenter';
import CompassConfirmRouter from '../../javascript/controllers/compass/payments/confirm/router';
import { walletShortname } from '../../javascript/helpers/wallet_helpers';
import { Payable } from '../../javascript/controllers/compass/model/payable';
import { IPaymentIntent } from '../../javascript/controllers/compass/model/payment_intent';
import { ICompassStatusViewInput } from '../../javascript/controllers/compass/payments/status/view_input';
import {
  Status,
  StatusType,
} from '../../javascript/controllers/compass/model/status';
import { PresailMagnetWrapper } from '../../javascript/web3/presail_magnet_wrapper';
import { Web3Account } from '../../javascript/web3/magnet/types';

export default class ConfirmationComponentController
  extends Web3Controller
  implements ICompassConfirmViewInput, ICompassStatusViewInput
{
  static targets = [
    'actionButton',
    'cancelButton',
    'statusView',
    'statusTitle',
    'statusMessage',
    'loadingStatusTitle',
    'loadingStatusMessage',
    'confirmationView',
    'loadingView',
    'countdown',
  ];

  declare readonly actionButtonTarget: HTMLButtonElement;
  declare readonly cancelButtonTarget: HTMLButtonElement;
  declare readonly statusViewTarget: HTMLElement;
  declare readonly statusTitleTarget: HTMLElement;
  declare readonly statusMessageTarget: HTMLElement;
  declare readonly loadingStatusTitleTarget: HTMLElement;
  declare readonly loadingStatusMessageTarget: HTMLElement;
  declare readonly confirmationViewTargets: [HTMLElement];
  declare readonly loadingViewTargets: [HTMLElement];
  declare readonly countdownTarget: HTMLElement;
  declare readonly hasCountdownTarget: boolean;
  declare stopCountingDown: boolean;

  static values = {
    compassBaseUrl: String,
    payable: Object,
    paymentIntent: Object,
    paymentState: String,
    successUrl: String,
    expiresAt: String,
  };
  declare readonly compassBaseUrlValue: string;
  declare readonly payableValue: Payable;
  declare readonly paymentIntentValue: IPaymentIntent;
  declare readonly paymentStateValue: string;
  declare readonly successUrlValue: string;
  declare readonly expiresAtValue: string;

  compassPresenter?: ICompassConfirmViewOutput & ICompassStatusViewOutput;

  async connect() {
    /*
     Need to wait for the next tick to have compassStatusOutlet instantiated.
     See https://stimulus.hotwired.dev/reference/lifecycle-callbacks#order-and-timing and
     https://github.com/hotwired/stimulus/issues/201
     */
    Promise.resolve().then(async () => {
      this.compassPresenter ||= new CompassConfirmPresenter({
        compassBaseUrl: this.compassBaseUrlValue,
        payable: this.payableValue,
        paymentIntent: this.paymentIntentValue,
        paymentState: this.paymentStateValue,
        expiresAt: new Date(this.expiresAtValue),
        view: this,
        statusView: this,
        router: new CompassConfirmRouter({
          application: this.application,
          compassBaseUrl: this.compassBaseUrlValue,
          successUrl: this.successUrlValue,
        }),
        addressFormatter: walletShortname,
      });
      await super.connect();
    });

    if (this.hasCountdownTarget) {
      this.countdown();
    }
  }

  disconnect() {
    this.stopCountingDown = true;
  }

  async onWeb3Initialized() {
    await super.onWeb3Initialized();
    await this.compassPresenter?.onWeb3Connect(
      new PresailMagnetWrapper(this.magnet!)
    );
  }

  onAccountConnected(account: Web3Account) {
    this.compassPresenter?.onWeb3ChainChange({
      id: this.magnet!.web3Account!.chain.id,
      name: this.magnet!.web3Account!.chain.name,
    });
    super.onAccountConnected(account);
  }

  onAccountDisconnected(account: Web3Account) {
    this.disableActionButton();
    super.onAccountDisconnected(account);
  }

  onActionButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onActionButtonClick();
  }

  onCancelButtonClick(event: Event): void {
    event.preventDefault();
    this.compassPresenter?.onCancelButtonClick();
  }

  enableActionButton(): void {
    this.actionButtonTarget.classList.remove('n-button--disabled');
  }

  disableActionButton(): void {
    this.actionButtonTarget.classList.add('n-button--disabled');
  }

  showBackOrCancelButton(): void {
    this.cancelButtonTarget.classList.remove('hidden');
  }

  hideBackOrCancelButton(): void {
    this.cancelButtonTarget.classList.add('hidden');
  }

  showPaymentIntentGUID(paymentIntentGUID: string): void {}

  /*
  StatusViewInput methods
   */
  showStatus({ title, statusType, message, linkText, linkUrl }: Status): void {
    if (statusType == StatusType.loading) {
      this.confirmationViewTargets.forEach((view) =>
        view.classList.add('hidden')
      );
      this.loadingViewTargets.forEach((view) =>
        view.classList.remove('hidden')
      );
      this.loadingStatusTitleTarget.textContent = title;
      this.loadingStatusMessageTarget.textContent = message || '';
    } else {
      this.confirmationViewTargets.forEach((view) =>
        view.classList.remove('hidden')
      );
      this.loadingViewTargets.forEach((view) => view.classList.add('hidden'));
      this.statusTitleTarget.textContent = title;
      this.statusMessageTarget.textContent = message || '';
      this.statusViewTarget.classList.remove('hidden');
    }
  }

  hideStatus(): void {
    this.statusViewTarget.classList.add('hidden');
  }

  countdown(): void {
    if (this.stopCountingDown) {
      return;
    }

    let currentNumber = parseInt(this.countdownTarget.textContent || '0', 10);
    if (currentNumber > 0) {
      this.countdownTarget.textContent = (currentNumber - 1).toString();
      setTimeout(() => this.countdown(), 1000);
    } else {
      // Close the form when the time expires, if it's in a state where it's possible to close
      if (!this.cancelButtonTarget.classList.contains('hidden')) {
        this.compassPresenter?.onCancelButtonClick();
      }
    }
  }
}
