import { IWeb3Client } from '../controllers/compass/web3/web3_client';
import { PreparedTransaction } from '../controllers/compass/model/prepared_transaction';
import { IBlockchainTransaction } from '../controllers/compass/model/blockchain_transaction';
import { IPaymentIntent } from '../controllers/compass/model/payment_intent';
import {
  CompassError,
  ErrorCode,
} from '../controllers/compass/model/compass_error';
import { TokenTxPreparedTransaction } from '../controllers/compass/model/prepared_transactions/token_tx_prepared_transaction';
import { DropletPreparedTransaction } from '../controllers/compass/model/prepared_transactions/droplet_prepared_transaction';
import { DeckPreparedTransaction } from '../controllers/compass/model/prepared_transactions/deck_prepared_transaction';
import { DropletPaymentIntent } from '../controllers/compass/model/intents/droplet_payment_intent';
import { ApproveAllowancePreparedTransaction } from '../controllers/compass/model/prepared_transactions/approve_allowance_prepared_transaction';
import Bugsnag from '@bugsnag/js';
import { Web3ConfigInfo } from '../controllers/compass/core/compass_api';
import { Magnet } from './magnet/magnet';
import { parseEther, parseUnits } from 'viem';
import { TokenTxPaymentIntent } from '../controllers/compass/model/intents/token_tx_payment_intent';
import { MagnetUserRejectedRequestError } from './magnet/errors';
import { PayableTransfer } from '../controllers/compass/model/payable_transfer';

/*
 * Wrapper around Magnet to adapt it to the IWeb3Client interface.
 */
export class PresailMagnetWrapper implements IWeb3Client {
  magnet: Magnet;

  constructor(magnet: Magnet) {
    this.magnet = magnet;
  }

  getAddress(): string | undefined {
    return this.magnet.web3Account?.address;
  }

  async getBalance(
    _addressIdentifier: string,
    tokenAddressIdentifier: string,
    chainIdentifier: number
  ): Promise<number> {
    const balance = await this.magnet.getBalance({
      tokenAddress: tokenAddressIdentifier as `0x${string}`,
      chainId: chainIdentifier,
    });
    return Number(balance.formatted);
  }

  async getPreparedTransaction(
    paymentIntent: IPaymentIntent
  ): Promise<PreparedTransaction> {
    try {
      if (paymentIntent.payable.acceptedPaymentMethod === 'deck') {
        const preparedTransaction = await this.magnet.prepareDeckLockTokens({
          tokenAddress: paymentIntent.paymentMethod.token
            .addressIdentifier as `0x${string}`,
          amount:
            parseUnits(
              paymentIntent.amount!.toString(),
              paymentIntent.paymentMethod.token.decimals
            ) || BigInt(0),
          merkleRoot: paymentIntent.payable.merkleRoot!,
          distributionIdToReplace:
            paymentIntent.payable.distributionToReplace || 0,
          taxed: !!paymentIntent.payable.forceExactAmountTransfer,
        });
        return new DeckPreparedTransaction(preparedTransaction);
      } else if (paymentIntent.payable.acceptedPaymentMethod === 'claim') {
        const preparedTransaction = await this.magnet.prepareDeckClaimTokens({
          distributionId: paymentIntent.payable.deckDistributionId,
          merkleIndex: paymentIntent.payable.deckMerkleIndex,
          amount:
            parseUnits(
              paymentIntent.amount!.toString(),
              paymentIntent.paymentMethod.token.decimals
            ) || BigInt(0),
          merkleProof: paymentIntent.payable.deckProof,
        });
        return new DeckPreparedTransaction(preparedTransaction);
      } else if (paymentIntent.payable.acceptedPaymentMethod === 'droplet') {
        if (paymentIntent.paymentMethod.token.native) {
          const preparedTransaction =
            await this.magnet.prepareDropletNativeDistribution({
              value: parseEther(paymentIntent.amount!.toString()),
              recipientsAddresses: paymentIntent.payable.transfers.map(
                (transfer: PayableTransfer) =>
                  transfer.toAddressIdentifier as `0x${string}`
              ),
              amounts: paymentIntent.payable.transfers.map(
                (transfer: PayableTransfer) =>
                  parseEther(transfer.amount.toString())
              ),
            });
          return new DropletPreparedTransaction(preparedTransaction);
        } else {
          const preparedTransaction =
            await this.magnet.prepareDropletTokenDistribution({
              tokenAddress: paymentIntent.paymentMethod.token
                .addressIdentifier as `0x${string}`,
              recipientsAddresses: paymentIntent.payable.transfers.map(
                (transfer: PayableTransfer) =>
                  transfer.toAddressIdentifier as `0x${string}`
              ),
              amounts: paymentIntent.payable.transfers.map(
                (transfer: PayableTransfer) =>
                  parseUnits(
                    transfer.amount.toString(),
                    paymentIntent.paymentMethod.token.decimals
                  )
              ),
              taxed:
                paymentIntent.paymentMethod.functionName ===
                'presailDistributeTokenSimple',
            });
          return new DropletPreparedTransaction(preparedTransaction);
        }
      } else {
        if (paymentIntent.paymentMethod.token.native) {
          const gas = await this.magnet.estimateGas({
            recipientAddress: (paymentIntent as TokenTxPaymentIntent)
              .toAddressIdentifier as `0x${string}`,
            value: parseEther(paymentIntent.amount!.toString()),
          });
          return new TokenTxPreparedTransaction(gas);
        } else {
          const preparedTransaction = await this.magnet.prepareTransfer({
            tokenAddress: paymentIntent.paymentMethod.token
              .addressIdentifier as `0x${string}`,
            amount: parseUnits(
              paymentIntent.amount!.toString(),
              paymentIntent.paymentMethod.token.decimals
            ),
            recipientAddress: (paymentIntent as TokenTxPaymentIntent)
              .toAddressIdentifier as `0x${string}`,
          });
          return new TokenTxPreparedTransaction(preparedTransaction);
        }
      }
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async pay(
    paymentIntent: IPaymentIntent,
    onTransactionSubmitted?: (hash: string) => void,
    onTransactionReplaced?: (params: any) => void,
    onTransactionCancelled?: (hash: string) => void
  ): Promise<IBlockchainTransaction> {
    try {
      if (
        paymentIntent.payable.acceptedPaymentMethod === 'transfer' &&
        paymentIntent.paymentMethod.token.native
      ) {
        return await this.magnet.sendTransaction({
          gas: BigInt(
            paymentIntent.preparedTransaction!.transaction as unknown as string
          ),
          recipientAddress: (paymentIntent as TokenTxPaymentIntent)
            .toAddressIdentifier as `0x${string}`,
          value: parseEther(paymentIntent.amount!.toString()),
          confirmations: 2,
          onTransactionSubmitted: onTransactionSubmitted,
          onTransactionConfirmed: undefined,
        });
      } else {
        return await this.magnet.writePreparedTransaction({
          preparedRequest: paymentIntent.preparedTransaction?.transaction,
          confirmations: 2,
          onTransactionSubmitted: onTransactionSubmitted,
          onTransactionConfirmed: undefined, // TODO
          onTransactionReplaced: onTransactionReplaced,
          onTransactionCancelled: onTransactionCancelled,
        });
      }
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async supportsChain(chainId: number): Promise<boolean> {
    return this.magnet.isChainSupported(chainId);
  }

  async switchChain(chainId: number): Promise<void> {
    try {
      await this.magnet.switchChain(chainId);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async fetchAllowance(paymentIntent: DropletPaymentIntent): Promise<bigint> {
    return (await this.magnet.allowance({
      tokenAddress: paymentIntent.paymentMethod.token
        .addressIdentifier as `0x${string}`,
      spenderAddress: paymentIntent.contractAddressIdentifier as `0x${string}`,
    })) as unknown as bigint;
  }

  async getPreparedAllowanceApproval(
    paymentIntent: DropletPaymentIntent
  ): Promise<ApproveAllowancePreparedTransaction> {
    const preparedTransaction = await this.magnet.prepareApproveAllowance({
      tokenAddress: paymentIntent.paymentMethod.token
        .addressIdentifier as `0x${string}`,
      spenderAddress: paymentIntent.contractAddressIdentifier as `0x${string}`,
      amount: parseUnits(
        paymentIntent.amount!.toString(),
        paymentIntent.paymentMethod.token.decimals
      ),
    });
    return Promise.resolve(new TokenTxPreparedTransaction(preparedTransaction));
  }

  async approveAllowance(
    preparedTransaction: PreparedTransaction,
    onTransactionSubmitted?: (hash: string) => void
  ): Promise<void> {
    try {
      await this.magnet.writePreparedTransaction({
        preparedRequest: preparedTransaction.transaction,
        onTransactionSubmitted: onTransactionSubmitted,
      });
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  getWeb3ConfigInfo(): Web3ConfigInfo {
    return {
      chain: this.magnet.web3Account?.chain.id,
      connector: this.magnet.web3Account?.connector?.id,
      account: this.magnet.web3Account?.address,
    };
  }

  /*
   * Private methods
   */
  private handleError(error: any): void {
    Bugsnag.notify(error);
    if (this.isUserRejectedTransactionError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.UserRejectedTransactionError,
      });
    } else if (this.isWalletNotConnectedError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.WalletNotConnectedError,
      });
    } else if (this.isFromAddressMismatchError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.FromAddressMismatchError,
      });
    } else if (this.isInsufficientAllowanceError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.InsufficientAllowanceError,
      });
    } else if (this.isInsufficientBalanceError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.InsufficientBalanceError,
      });
    } else if (this.isInsufficientBalanceForFeesError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.InsufficientBalanceForFeesError,
      });
    } else if (this.isInvalidNonceError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.InvalidNonceError,
      });
    } else if (
      this.isUnsupportedChain(error as Error) ||
      this.isChainNotFoundOnConnectorError(error as Error)
    ) {
      throw new CompassError({
        code: ErrorCode.UnsupportedChainError,
      });
    } else if (this.isBlindSigningDisabledError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.BlindSigningDisabledError,
      });
    } else if (this.isContractExecutionTakingTooLongError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.ContractExecutionTakingTooLongError,
      });
    } else if (this.isUnknownRPCNoKeyringError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.UnkownRPCNoKeyringError,
      });
    } else if (this.isUnknownRPCTransactionUnderpricedError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.UnkownRPCTransactionUnderpricedError,
      });
    } else if (this.isUnknownRPCError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.UnkownRPCError,
      });
    } else if (this.isUnknownLedgerError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.UnknownLedgerError,
      });
    } else if (this.isMetamaskHavingTroubleConnectingError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.MetamaskHavingTroubleConnectingError,
      });
    } else if (this.isLedgerDeviceInvalidDataReceivedError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.LedgerDeviceInvalidDataReceivedError,
      });
    } else if (this.isCannotSetDefaultChainError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.CannotSetDefaultChainError,
      });
    } else if (this.isGenericSwitchChainError(error as Error)) {
      throw new CompassError({
        code: ErrorCode.GenericSwitchChainError,
      });
    } else if (this.isDeckDistributionInvalidated(error as Error)) {
      throw new CompassError({
        code: ErrorCode.DeckDistributionInvalidated,
      });
    } else if (this.isDeckDistributionAlreadyClaimed(error as Error)) {
      throw new CompassError({
        code: ErrorCode.DeckDistributionAlreadyClaimed,
      });
    } else if (this.isDeckDistributionForceNonExact(error as Error)) {
      throw new CompassError({
        code: ErrorCode.DeckDistributionForceNonExact,
      });
    }
    throw error;
  }

  private isUserRejectedTransactionError(error: Error): boolean {
    return (
      error.message.includes('rejected') ||
      error.message.includes('User canceled') ||
      error.message.includes('User disapproved requested methods') || // Wagmi
      error.message.includes('denied by the user') ||
      error instanceof MagnetUserRejectedRequestError
    );
  }

  private isWalletNotConnectedError(error: Error): boolean {
    return error.message.includes('Connector not found');
  }

  private isFromAddressMismatchError(error: Error): boolean {
    return error.message.includes('from address mismatch');
  }

  private isInsufficientAllowanceError(error: Error): boolean {
    return (
      error.message.includes('insufficient allowance') ||
      error.message.includes('transfer amount exceeds allowance')
    );
  }

  private isInsufficientBalanceError(error: Error): boolean {
    return error.message.includes('transfer amount exceeds balance');
  }

  private isInsufficientBalanceForFeesError(error: Error): boolean {
    return error.message.includes(
      'The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account'
    );
  }

  private isInvalidNonceError(error: Error): boolean {
    return (
      error.message.includes('nonce has already been used') ||
      error.message.includes('nonce too low') ||
      error.message.includes("doesn't have the correct nonce")
    );
  }

  private isUnsupportedChain(error: Error): boolean {
    return error.message.includes(
      'not approved. Please use one of the following'
    );
  }

  private isBlindSigningDisabledError(error: Error): boolean {
    return error.message.includes('enable Blind signing or Contract data');
  }

  private isContractExecutionTakingTooLongError(error: Error): boolean {
    return error.message.includes(
      'ContractFunctionExecutionError: The request took too long to respond'
    );
  }

  private isUnknownRPCNoKeyringError(error: Error): boolean {
    return (
      error.message.includes('unknown RPC error') &&
      error.message.includes('No keyring found for the requested account')
    );
  }

  private isUnknownRPCTransactionUnderpricedError(error: Error): boolean {
    return error.message.includes('transaction underpriced');
  }

  private isUnknownRPCError(error: Error): boolean {
    return error.message.includes('unknown RPC error');
  }

  private isUnknownLedgerError(error: Error): boolean {
    return error.message.includes('Ledger device: UNKNOWN_ERROR');
  }

  private isMetamaskHavingTroubleConnectingError(error: Error): boolean {
    return error.message.includes('MetaMask is having trouble connecting');
  }

  private isLedgerDeviceInvalidDataReceivedError(error: Error): boolean {
    return error.message.includes('Ledger device: Invalid data received');
  }

  private isChainNotFoundOnConnectorError(error: Error): boolean {
    return error.message.includes('chain not found on connector');
  }

  private isCannotSetDefaultChainError(error: Error): boolean {
    return (
      error.message.includes(
        'An error occurred when attempting to switch chain'
      ) &&
      error.message.includes(
        "Cannot set properties of undefined (setting 'defaultChain')"
      )
    );
  }

  private isGenericSwitchChainError(error: Error): boolean {
    return (
      error.message.includes(
        'An error occurred when attempting to switch chain'
      ) && !this.isCannotSetDefaultChainError(error)
    );
  }

  private isDeckDistributionInvalidated(error: Error): boolean {
    return error.message.includes('Error: DistributionInvalidated');
  }

  private isDeckDistributionAlreadyClaimed(error: Error): boolean {
    return error.message.includes('Error: TokensAlreadyClaimed');
  }

  private isDeckDistributionForceNonExact(error: Error): boolean {
    return error.message.includes('Error: NonExactAmountTransfer');
  }
}
