import { Controller } from 'stimulus';
import Chart, { registerables } from 'chart.js/auto';
import { gsap } from 'gsap';

Chart.register(...registerables);

export default class extends Controller {
  static targets = ['canvas', 'spinner', 'noAllocations'];
  static values = { canvasId: String };

  connect() {
    const url = '/partner/dashboard/fetch_allocation_table_data';
    fetch(url).then(response => response.json()).then(data => {
      this.handleData(data);
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  handleData(data) {
    gsap.to(this.spinnerTarget, {
      opacity: 0,
      duration: 0.5,
      onComplete: () => {
        this.spinnerTarget.classList.add('hidden');

        if (data.length === 0) {
          this.noAllocationsTarget.classList.remove('hidden');
          this.noAllocationsTarget.classList.add('flex');
          gsap.fromTo(this.noAllocationsTarget, { opacity: 0 }, { opacity: 1, duration: 0.5 });
        } else {
          this.canvasTarget.classList.remove('hidden');
          gsap.fromTo(this.canvasTarget, { opacity: 0 }, { opacity: 1, duration: 0.5 });
          this.loadChart(data);

          // Use outlets to notify the table-pagination controller
          const tablePaginationController = this.application.getControllerForElementAndIdentifier(
            document.querySelector('[data-controller="table-pagination"]'),
            'table-pagination'
          );
          if (tablePaginationController) {
            tablePaginationController.updateData(data);
          }
        }
      }
    });
  }

  loadChart(data) {
    if (!this.hasCanvasIdValue) {
      console.error('canvasIdValue is required');
      return;
    }

    const ctx = document.getElementById(this.canvasIdValue).getContext('2d');

    if (!ctx) {
      console.error('canvas not found');
      return;
    }

    const labels = data.map(item => item.name);
    const share = data.map(item => item.share);
    const colours = data.map(item => item.background_color);
    const investedAmount = data.map(item => item.invested_amount);

    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          data: share,
          backgroundColor: colours,
          investedAmount: investedAmount,
          borderWidth: 0 // to remove border between segments
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,  // Disable the default tooltip
            external: function(context) {
              let tooltipEl = document.getElementById('chartjs-tooltip');
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.style.opacity = 0;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.transition = 'opacity 0.3s ease, left 0.3s ease, top 0.3s ease';
                tooltipEl.innerHTML = `
                  <div class="bg-background font-sans border-[1px] border-stroke text-white rounded-2xl shadow-lg px-4 py-3">
                    <div></div>
                  </div>
                `;
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip passed to context
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Build custom tooltip
              if (tooltipModel.body) {
                const name = tooltipModel.title[0];
                const share = tooltipModel.dataPoints[0].raw;
                const colors = tooltipModel.labelColors[0];
                const investedAmount = context.chart.data.datasets[0].investedAmount[tooltipModel.dataPoints[0].dataIndex];
                let innerHtml = `
                  <div class="flex flex-col">
                    <div class="flex items-center mb-[0.38rem]">
                      <span class="shrink-0 w-4 h-4 mr-3 rounded" style="background:${colors.backgroundColor}"></span>
                      <span class="text-[0.875rem]">${name}, ${share}%</span>
                    </div>
                    <span class="text-foreground-light text-sm">$${parseFloat(investedAmount).toLocaleString()} allocated</span>
                  </div>
                `;
                const divRoot = tooltipEl.querySelector('div');
                divRoot.innerHTML = innerHtml;
              }

              // Position calculation
              const position = context.chart.canvas.getBoundingClientRect();
              const scrollY = window.scrollY;
              const scrollX = window.scrollX;
              const tooltipWidth = tooltipEl.offsetWidth;
              const tooltipHeight = tooltipEl.offsetHeight;

              let tooltipX = position.left + scrollX + tooltipModel.caretX;
              let tooltipY = position.top + scrollY + tooltipModel.caretY;

              // Ensure tooltip is within window bounds
              const padding = 10;
              if (tooltipX + tooltipWidth + padding > window.innerWidth) {
                tooltipX = window.innerWidth - tooltipWidth - padding;
              }
              if (tooltipX < padding) {
                tooltipX = padding;
              }
              if (tooltipY + tooltipHeight + padding > window.innerHeight + scrollY) {
                tooltipY = window.innerHeight + scrollY - tooltipHeight - padding;
              }
              if (tooltipY < padding + scrollY) {
                tooltipY = padding + scrollY;
              }

              // Apply the position
              tooltipEl.style.opacity = 1;
              tooltipEl.style.left = tooltipX + 'px';
              tooltipEl.style.top = tooltipY + 'px';
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.display = 'block';
            }
          }
        },
        cutout: '70%', // this creates the donut effect
      }
    });
  }
}
