import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    inputId: String,
    submitOnChange: { type: Boolean, default: false }
  }

  static targets = ['container', 'notEnabled', 'enabled']

  connect() {
    this.inputElement = document.getElementById(this.inputIdValue);
    if (!this.inputElement) {
      console.error('Could not find input element with id:', this.inputIdValue);
    }
  }

  toggle(event) {
    event.preventDefault();
    const confirmMessage = this.inputElement.form?.querySelector('[type="submit"]').dataset.confirm;
    if (!confirmMessage || confirm(confirmMessage)) {
      this.inputElement.checked = !this.inputElement.checked;
      // If the checkbox has any change event listeners, we need to trigger them manually
      // See checkbox toggle_cap_amount for an example
      const event = new Event('change', {
        bubbles: true,
        cancelable: true,
      });
      this.inputElement.dispatchEvent(event);

      this.updateToggle();

      if (this.submitOnChangeValue) {
        this.inputElement.form.requestSubmit();
      }
    }
  }

  updateToggle() {
    if (this.inputElement.checked) {
      this.element.classList.remove('bg-gray-200', 'dark:bg-gray-700');
      this.element.classList.add('bg-sky-medium');

      this.containerTarget.classList.remove('translate-x-0');
      this.containerTarget.classList.add('translate-x-5');

      this.notEnabledTarget.classList.remove('opacity-100', 'duration-200', 'ease-in');
      this.notEnabledTarget.classList.add('opacity-0', 'duration-100', 'ease-out');

      this.enabledTarget.classList.remove('opacity-0', 'duration-100', 'ease-out');
      this.enabledTarget.classList.add('opacity-100', 'duration-200', 'ease-in');
    } else {
      this.element.classList.remove('bg-sky-medium');
      this.element.classList.add('bg-gray-200', 'dark:bg-gray-700');

      this.containerTarget.classList.remove('translate-x-5');
      this.containerTarget.classList.add('translate-x-0');

      this.notEnabledTarget.classList.remove('opacity-0', 'duration-100', 'ease-out');
      this.notEnabledTarget.classList.add('opacity-100', 'duration-200', 'ease-in');

      this.enabledTarget.classList.remove('opacity-100', 'duration-200', 'ease-in');
      this.enabledTarget.classList.add('opacity-0', 'duration-100', 'ease-out');
    }
  }
}
