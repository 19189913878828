import { MDCFormField } from '@material/form-field';
import { MDCTextField } from '@material/textfield';
import { MDCTextFieldIcon } from '@material/textfield/icon';
import { MDCSelect } from '@material/select';

// This is for instantiating the text field components throughout the app
const formField = [].map.call(
  document.querySelectorAll('.mdc-form-field'),
  function (el: HTMLElement) {
    return new MDCFormField(el);
  }
);

export function initializeTextField() {
  [].map.call(
    document.querySelectorAll('.mdc-text-field'),
    (el: HTMLElement) => {
      return new MDCTextField(el);
    }
  );

  [].map.call(
    document.querySelectorAll('.mdc-text-field__icon'),
    (el: HTMLElement) => {
      return new MDCTextFieldIcon(el);
    }
  );
}

const select = [].map.call(
  document.querySelectorAll('.mdc-select'),
  function (el: HTMLElement) {
    return new MDCSelect(el);
  }
);

