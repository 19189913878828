import { Controller } from 'stimulus';
import { gsap } from "gsap";

class CardAnimationController extends Controller {
  static targets = ['card', 'background', 'bottomCard', 'innerCard'];

  connect() {
    this.tl = gsap.timeline({ paused: true });
    this.tl.to(this.backgroundTarget, { duration: 0.2, opacity: 0.5 }, 0)
    if(this.hasInnerCardTarget) {
      this.tl.to(this.innerCardTarget, { duration: 0.2, borderColor: '#6466E9', boxShadow: 'var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) #6466E9' }, 0)
    }
    this.boundPlay = this.play.bind(this);
    this.boundReverse = this.reverse.bind(this);


    this.cardTarget.addEventListener('mouseenter', this.boundPlay);
    this.cardTarget.addEventListener('mouseleave', this.boundReverse);
  }

  disconnect() {
    this.cardTarget.removeEventListener('mouseenter', this.boundPlay);
    this.cardTarget.removeEventListener('mouseleave', this.boundReverse);
  }

  play() {
    this.tl.play();
  }

  reverse() {
    this.tl.reverse();
  }

}

export default CardAnimationController;
